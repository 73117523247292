/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('./permisos');



window.Vue = require('vue');
import VueRouter from 'vue-router';
Vue.use(VueRouter);


import mitt from "mitt";
export const bus = mitt();
Vue.prototype.$bus = bus;
bus.$on = bus.on;
bus.$emit = bus.emit;
window.bus = bus;

 import mqtt from "@/mqtt";
 Vue.use(mqtt);


import Sticky from "vue-sticky-directive";
Vue.use(Sticky);

import { createPopper } from '@popperjs/core';
window.createPopper=createPopper


/*
import moment from 'moment';
Vue.use(moment)
*/
/*
*/

/**
 * cargamos toda la libreria bootstrap
 */

/*
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
*/

/*
import BoostrapVue from 'bootstrap-vue';
*/
 import { BootstrapVue } from 'bootstrap-vue'
 Vue.use(BootstrapVue);


 
window.cancelRequestMixin = {
    data: function () {
      return {
        AxiosCancelSources: {},
      }
    },
    // created: function () {
    //   this.hello()
    // },
    methods: {
      fnCancelRequest: function (
        key,
        cancelled_message,
        cancelled_code,
      ) {
        console.log('fnCancelRequest2')
        if (!key) key = 'default'
        if (
          this.AxiosCancelSources[key] &&
          typeof this.AxiosCancelSources[key].cancel !=
            typeof undefined
        ) {
          this.AxiosCancelSources[key].cancel(
            cancelled_message
              ? cancelled_message
              : 'stopping current request: ' + key,
            cancelled_code ? cancelled_code : 1,
          )
        }
        this.AxiosCancelSources[key] =
          axios.CancelToken.source()
        return this.AxiosCancelSources[key].token
      },
    },
  }

  
 import VuePromiseBtn from "vue-promise-btn";
 Vue.use(VuePromiseBtn);
 Vue.component("info-persona", () => import("./components/rrhh/persona/PersonaInfo.vue"));
 Vue.component("periodo-select", () => import("./components/referencias/PeriodoSelect.vue"));
 Vue.component("b-button", () => import("./components/otros/ElButton.vue"));

Vue.component('b-select', () => import('./components/general/elSelect.vue'));
//Vue.component('b-select', () => import('./components/general/elAutoSelect.vue'));

// import {BDropdown} from 'bootstrap-vue';Vue.component("b-dropdown", BDropdown);
// import {BDropdownItem} from 'bootstrap-vue';Vue.component("b-dropdown-item", BDropdownItem);
// import {BCol} from 'bootstrap-vue';Vue.component("b-col", BCol);
// import {BRow} from 'bootstrap-vue';Vue.component("b-row", BRow);
// import {BTable} from 'bootstrap-vue';Vue.component("b-table", BTable);
// import {BModal} from 'bootstrap-vue';Vue.component("b-modal", BModal);
// import {BFormCheckbox} from 'bootstrap-vue';Vue.component("b-form-checkbox", BFormCheckbox);
// import {BFormSelect} from 'bootstrap-vue';Vue.component("b-form-select", BFormSelect);
// import {BButtonGroup} from 'bootstrap-vue';Vue.component("b-button-group", BButtonGroup);
// import {BInputGroup} from 'bootstrap-vue';Vue.component("b-input-group", BInputGroup);
// import {BInputGroupAppend} from 'bootstrap-vue';Vue.component("b-input-group-append", BInputGroupAppend);


import BootstrapVueTreeview from 'bootstrap-vue-treeview'
Vue.use(BootstrapVueTreeview)

/**
 * Cargamos solo partes de bootstrap
 import { BootstrapVue } from 'bootstrap-vue'
 Vue.use(BoostrapVue);
 */




import permission from '@/directive/permission/index.js'
Vue.directive(
  'permission', permission
)
import checkPermission from '@/utils/permission'
export default {
  methods: {
    checkPermission
  }
}


Vue.component('d-table', require("./components/otros/Table/Main.vue").default)


import DatePicker from '@/components/otros/DatePicker';

Vue.component('date-picker', DatePicker);

import TimePicker from '@/components/general/TimePicker'
Vue.component('b-form-timepicker',TimePicker)


import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
Vue.use(VueBootstrapTypeahead);
//Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead);
import vSelect from 'vue-select';
Vue.component('v-select', vSelect);
import * as filters from './filters'; // global filters
// register global utility filters
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});




//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'
//import '@/../sass/bootstrap.css'

/**
    Aca definimos las rutas
 */

/*
const router = new VueRouter({
    mode: 'history',
    routes: require('@/router').default
});
*/



import store from './store';
import router from './router';
//const ElTable = require("@/components/otros/ElTable.vue").default;
const ElTable = () => import("@/components/otros/ElTable.vue");
Vue.component("el-table", ElTable);
const ElTable2 = () => import("@/components/otros/ElTable2.vue");

Vue.component("el-table-2", ElTable2);


const ElTable3 = () => import("@/components/otros/ElTable3.vue");
Vue.component("el-table-3", ElTable3);


/*
Vue.component(
    "v-listar-persona",
    require("./components/rrhh/persona/ListarPersona.vue").default
);
Vue.component(
    "v-listar-modalidad",
    require("./components/rrhh/modalidad/ListarModalidad.vue").default
    );
Vue.component(
    "v-listar-cargo",
    require("./components/rrhh/cargo/ListarCargo.vue").default
);
Vue.component(
    "v-listar-regimen",
    require("./components/rrhh/regimen/ListarRegimen.vue").default
);

Vue.component(
    "v-listar-escala",
    require("./components/rrhh/escala/ListarEscala.vue").default
);

Vue.component(
    "v-listar-sede",
    require("./components/rrhh/sede/ListarSede.vue").default
);
Vue.component(
    "v-listar-oficina",
    require("./components/rrhh/oficina/ListarOficina.vue").default
    );
Vue.component(
    "v-listar-afp",
    require("./components/rrhh/afp/ListarAfp.vue").default
);
      */
/*
Vue.component(
    "v-listar-banco",
    require("./components/rrhh/banco/ListarBanco.vue").default
);
Vue.component(
    "v-listar-vendedor",
    require("./components/rrhh/vendedor/ListarVendedor.vue").default
);
Vue.component(
    "v-listar-capacitacion",
    require("./components/rrhh/capacitacion_sala/ListarCapacitacion.vue").default
);
Vue.component(
    "v-listar-capacitacion-campo",
    require("./components/rrhh/capacitacion_campo/ListarCapacitacion.vue").default
);
Vue.component(
    "v-listar-venta",
    require("./components/rrhh/venta/ListarVenta.vue").default
);

Vue.component(
    "v-listar-personal",
    require("./components/rrhh/personal/ListarPersonal.vue").default
);

Vue.component(
    "v-listar-base-general",
    require("./components/rrhh/base_general/ListarBaseGeneral.vue").default
);
Vue.component(
    "v-listar-cuenta",
    require("./components/rrhh/cuenta/ListarCuenta.vue").default
);
Vue.component(
    "v-listar-incidencia",
    require("./components/rrhh/incidencia/ListarIncidencia.vue").default
);

Vue.component(
    "v-listar-averia",
    require("./components/rrhh/averia/ListarAveria.vue").default
);
Vue.component(
    "v-listar-arbitraje-interno",
    require("./components/rrhh/arbitraje_interno/ListarArbitrajeInterno.vue").default
);
Vue.component(
    "v-listar-arbitraje-externo",
    require("./components/rrhh/arbitraje_externo/ListarArbitrajeExterno.vue").default
);
Vue.component(
    "v-listar-alta-baja",
    require("./components/rrhh/alta_baja/ListarAltaBaja.vue").default
);
Vue.component(
    "v-listar-fraude",
    require("./components/rrhh/fraude/ListarFraude.vue").default
);
Vue.component(
    "v-listar-otro",
    require("./components/rrhh/otro/ListarOtro.vue").default
);
Vue.component(
    "v-listar-motivo-cancelacion",
    require("./components/rrhh/motivo_cancelacion/ListarMotivoCancelacion.vue").default
);
Vue.component(
    "v-listar-producto",
    require("./components/rrhh/producto/ListarProducto.vue").default
);
Vue.component(
    "v-listar-sub-categoria",
    require("./components/rrhh/sub_categoria/ListarSubCategoria.vue").default
);
 */
/*
Vue.component(
    "v-crear-persona",
    require("./components/rrhh/persona/CrearPersona.vue").default
);
Vue.component(
    "v-editar-persona",
    require("./components/rrhh/persona/CrearPersona.vue").default
);
*/
//Importar
/**
 *
 Vue.component(
    "v-importar-excel-persona",
    require("./components/rrhh/persona/ImportarExcel.vue").default
);
*/
const LoadingBar = () => import("@/components/otros/LoadingBar.vue");
Vue.component("loading-bar", LoadingBar);
const ElDialog = () => import("@/components/otros/ElDialog.vue");
Vue.component("el-dialog", ElDialog);

/*
const VMath = () => import("@/components/general/vMath/vMath.vue");
Vue.component("v-math", VMath);
*/

const FDinamica = () => import("@/components/rrhh/concepto_planilla/FormulaDinamica.vue");
Vue.component("v-formula-dinamica", FDinamica);



Vue.component("b-form-input", {
    /* props: ['value'], */
    props: {
        value: {
            type: String | Number,
            default: ""
        },
        tipo: {
            type: String | Number,
            default: ""
        }
    },
    data() {
        return {
            innerValue: ""
        };
    },
    template: `<input class="form-control form-control-sm" v-model="innerValue" @blur="handleBlur" @keyup.enter="keyupEnter"/>`,
    watch: {
        // Handles internal model changes.
        innerValue(newVal) {
            this.$nextTick(() => {
                this.$emit("input", newVal);
            });
        },
        // Handles external model changes.
        value(newVal) {
            if (newVal && this.tipo != "no_mayus") {
                this.innerValue = String(newVal).toUpperCase();
            } else if (newVal == "") {
                this.innerValue = "";
            } else if (!newVal) {
                this.innerValue = "";
            } else {
                this.innerValue = newVal;
            }
        }
    },
    methods: {
        handleBlur(x) {
            this.innerValue = this.innerValue.trim();
        },
        keyupEnter() {
            this.$emit("keyupEnter");
        }
    },
    created() {
        if (this.value) {
            this.innerValue = String(this.value).toUpperCase();
        }
    }
});
window.fnCancelAction = function(key, cancelled_message, cancelled_code, self) {
    if (!key) key = "default";
    if (!self) {
        if (!window.tokenCancelRequest)
            window.tokenCancelRequest = { AxiosCancelSources: {} };
        self = window.tokenCancelRequest;
    }
    if (
        self.AxiosCancelSources[key] &&
        typeof self.AxiosCancelSources[key].cancel != typeof undefined
    ) {
        self.AxiosCancelSources[key].cancel(
            cancelled_message
                ? cancelled_message
                : "stopping current request: " + key,
            cancelled_code ? cancelled_code : 1
        );
    }
    self.AxiosCancelSources[key] = axios.CancelToken.source();
    return self.AxiosCancelSources[key];
};

window.fnCancelRequest = function(
    key,
    cancelled_message,
    cancelled_code,
    self
) {
    let x = window.fnCancelAction(key, cancelled_message, cancelled_code, self);
    return x.token;
};


 

window.messageMixin={
    methods:{
        $message(msg){
            if(typeof msg=='object')
            {
                if(msg.message){
                    alert(msg.message)
                }
                else if(msg.msg){
                    alert(msg.msg)
                }else{
                    alert(JSON.stringify(msg))
                }
            }
            else
            alert(msg)
        }
    }
}
 
Vue.mixin(messageMixin)
 


/**
 * Inicializamos el constructor (esto debe ir al final)
 */
import App from './views/App';


const app = new Vue({

    el: '#app',
    components: { App },
    router,
    store,

});

