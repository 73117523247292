import axios from 'axios'
//import { MessageBox, Message } from 'element-ui'
import {
    MessageBox,
    Message,
    Notification
} from '@/utils/messages';

 
import store from '@/store';
import {
    getToken
} from '@/utils/auth';


// create an axios instance
const service = axios.create({
    //baseURL: process.env.VUE_APP_BASE_API?, // url = base url + request url
    baseURL: '/api', // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 60 * 10 * 1000 // request timeout 10 minutos
});

// Map to store cancel tokens
//const cancelTokens = new Map();

// request interceptor
service.interceptors.request.use(
    config => {
        /**

     console.log('config', config)
     if (config.method == 'OPTIONS') {
       delete (config.data)
       delete (config.params)
      }
*/
        // do something before request is sent
        if (store.getters.token) {
            //console.log('request->token', store.getters.token);
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            // config.headers['X-Token'] = getToken()

            config.headers.Authorization = 'Bearer ' + getToken();
            // config.headers['Content-Type'] = 'multipart/form-data'
            // config.headers['Accept'] = 'application/json'

        }
        else {
            console.log('no ', store.getters.token);
        }


        try{

        
         if (config.uniqueKey) {
            console.log('cancel request ini',config.uniqueKey)
        //     // Cancel the previous request if it exists
        //     if (cancelTokens.has(config.uniqueKey)) {
        //          console.log('cancel',config.uniqueKey)
        //         cancelTokens.get(config.uniqueKey).cancel('Operation canceled due to new request.');
        //     }

        //     // Create a new cancel token
        //     const source = axios.CancelToken.source();
        //     config.cancelToken = source.token;
        //     cancelTokens.set(config.uniqueKey, source);
         }
        }catch(e){
            console.log('error',e)
        }


        return config;
    },
    error => {
        // do something with request error
        console.log('error w', error); // for debug
        return Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data;

        // if the custom code is not 20000, it is judged as an error.
//  if (response.config.uniqueKey) {
//             cancelTokens.delete(response.config.uniqueKey);
//         }
        /**
         * control para acceso a api rest laravel
         */
        //        console.log('response', response)
        if (!res.code && res.msg && response.status === 200) {
            return response;
        }
        /**
         * fin api rest laravel
         */

        if (res.code && res.code !== 20000) {
            console.log('error v', res);
            Message({
                message: res.message || 'Error',

                type: 'error',
                duration: 5 * 1000
            });

            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
                // to re-login
                store.dispatch('user/resetToken').then(() => {
                })
                /*
                MessageBox.confirm('Se cerró su sesión, presiona cancelar para permanecer en esta página, o iniciar sessión nuevamente', 'Cerrar sesión', {
                    confirmButtonText: 'Re.Iniciar sesión',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(() => {
                    store.dispatch('user/resetToken').then(() => {
                        location.reload();
                    });
                });
                */
            }

            console.log('err y');
            return Promise.reject(new Error(res.message || 'Error'));
        } else {
            return res;
        }
    },
    error => {


        /**
         * control para acceso a api rest laravel
         */
        const originalRequest = error.config;

        if (axios.isCancel(error)) {
            console.log('Request canceled:', error.message);
            console.log('uniqueKey',originalRequest.uniqueKey)
            // if (originalRequest.uniqueKey) {
            //     cancelTokens.delete(originalRequest.uniqueKey);
            // }
            return Promise.reject(error);
        } else {

        console.log('errorerror', error, error.config, error.request);
        if (error.request) {
            if (error.request.status === 429) {
                // too many Attemps
                location.reload();
                return null;
            }
            else if (error.request.status === 401) {
                switch (error.response.data.message) {
                    case 'Token has expired':
                        /*
                        return Promise.reject(error);
                        */

                        console.log('expired')
                        //alert('expired')
                        if (originalRequest._retry)
                            originalRequest._retry++
                        else
                            originalRequest._retry = 1;
                        return store.dispatch('user/refreshToken').then((data) => {
                            // location.reload();
                            if (originalRequest._retry == 1) {
                                if (originalRequest.url.indexOf(originalRequest.baseURL) == 0) {
                                    originalRequest.url = originalRequest.url.replace(originalRequest.baseURL, '');
                                }
                                console.log('retry request', originalRequest)

                                axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
                                originalRequest.headers['Authorization'] = 'Bearer ' + data.token;
                                return service(originalRequest);
                            }
                        });

                        break;
                    /*
                    case 'The token has been blacklisted':
                        store.dispatch('user/resetToken').then(() => {
                            // location.reload();
                        });
                        break;
                    */
                    default:
                        if (error.request.responseURL === process.env.VUE_APP_BASE_API + '/user/login') {
                            console.log('error xz1', error.request);
                            Message({
                                message: error.request.response || 'Usuario y/o clave incorrecta',
                                type: 'error',
                                duration: 2 * 1000
                            });
                            /*
                            Notification.error({
                                title: 'Error',
                                dangerouslyUseHTMLString: true,
                                message: 'Usuario y/o clave incorrecta'
                            })
                            */
                            /*
                            MessageBox.alert('Usuario y/o clave incorrecta', 'Autorización', {
                                type: 'danger'
                             }).then(() => {
                                 return false
                             })
                             */
                        } else {
                            console.log('Desconectado, por favor vuelva a iniciar la sesion')
                            store.dispatch('user/resetToken').then(() => {
                                // location.reload();
                            })
                            /*
                            ;
                            */
                            /*
                            MessageBox.confirm('Se cerró su sesión, presiona cancelar para permanecer en esta página, o iniciar sessión nuevamente', 'Cerrar sesión', {
                                confirmButtonText: 'Re.Iniciar sesión',
                                cancelButtonText: 'Cancelar',
                                type: 'warning'
                            }).then(() => {
                                store.dispatch('user/resetToken').then(() => {
                                    location.reload();
                                });
                            });
                            */
                            console.log('error z', error.request.responseURL, process.env.VUE_APP_BASE_API + '/user/url');
                        }

                        const res = error.response.data;
                        //throw (new Error(res.message || 'Error'))
                        return Promise.reject(new Error(res.message || 'Error'));
                }

            }
            else if (error.request.status === 500) {
                switch (error.response.data.message) {

                    case 'Token has expired':
                        store.dispatch('user/refreshToken').then(() => {
                            // location.reload();
                        });
                        break;

                    case 'The token has been blacklisted':

                        if (error.config.headers.Authorization != 'Bearer ' + getToken()) {
                            //console.log('tthb', error.config, getToken(), error)
                            //diferent token, it means it was renewed in other request
                            //alert('tthbb')
                            return Promise.resolve('token renewed');
                        }
                        else {
                            //same token, it means the token wasn't renewed
                            store.dispatch('user/resetToken').then(() => {
                                // location.reload();
                            });
                            return Promise.resolve('token blacklisted');
                        }

                        /*
                        store.dispatch('user/resetToken').then(() => {
                            // location.reload();
                        });
                        */
                        break;
                    case "Token has expired and can no longer be refreshed":

                        store.dispatch("user/logout").then(() => {
                            window.location.reload()
                        });
                        break;
                    default:
                }
            } else if (error.request.status === 501) {
                MessageBox.confirm('Se cerró su sesión, presiona cancelar para permanecer en esta página, o iniciar sessión nuevamente', 'Cerrar sesión', {
                    confirmButtonText: 'Re.Iniciar sesión',
                    cancelButtonText: 'Cancelar',
                    type: 'warning'
                }).then(() => {
                    store.dispatch('user/resetToken').then(() => {
                        location.reload();
                    });
                });
                const res = error.response.data;
                console.log('error validacion', error);
                return Promise.reject(new Error(res.message || 'Error'));
            } else if (error.request.status === 422) {
                console.error(error);
                if (error.response && error.response.data) {
                    let message = '';
                    if (error.response.data.errors) {
                        let errors = error.response.data.errors;
                        for (let i in errors) {
                            if (errors[i][0]) {

                                message += ' ' + errors[i][0];
                            } else {
                                message += ' Error:' + i;
                            }
                        }
                    } else {
                        message = error.response.data.message ? error.response.data.message : error.message;
                    }

                    Notification.error({
                        title: 'Error de validación',
                        dangerouslyUseHTMLString: true,
                        message: message
                    });

                    return Promise.reject(error.response.data);
                } else {
                    return Promise.reject(error);
                }

            } else {
            /**/
                console.log('otro error')
            }
        }
        else {
            switch (error.message) {
                case 'can\'t access dead object':
                    location.reload();
                    return;
            }
        }
        /**
         * fin api rest laravel
         */

        console.log('err', error); // for debug
        let msg = error.message;
        if(error.__proto__.__CANCEL__===true){
            return Promise.reject(error)
        }else{

            if (error.response && error.response.data) {
                if (error.response.data.msg) msg = error.response.data.msg;
                else if (error.response.data.message) msg = error.response.data.message;
            }
            Message({
                message: msg,
                type: 'error',
                duration: 5 * 1000
            });

            
            if (originalRequest.uniqueKey) {
                console.log('cancelToken2',originalRequest.uniqueKey)
                // cancelTokens.delete(originalRequest.uniqueKey);
            }

            return Promise.reject(error);
        }
    }

    }
);

export default service
