import { login, logout, getInfo, refreshToken, ping } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'
// 
import { IfVisible } from '@/utils/ifvisible.js'

const ifvisible = new IfVisible(window, window.document)


const state = {
  visible: null,
  idle: null,
  initialized: false
};

const mutations = {
  SET_VISIBLE: (state, visible) => {
    state.visible = visible;
    setToken(visible)
  },
  SET_IDLE: (state, idle) => {
    state.idle = idle;
    setToken(idle)
  },
};
//self.$store.getters['activity/idle']
//self.$store.getters['activity/visible']

const getters = {
  visible: state => {
    return ifvisible.now("focus")
  },
  idle: state => {
    return ifvisible.now("idle")
  },
}

const actions = {
  start({ commit, state }) {
    if (state.initialized) return
    state.initialized = true
    ifvisible.on("blur", function () {
      // example code here..
      commit('SET_VISIBLE', false);
      console.log('blur')
    });

    ifvisible.on("focus", function () {
      // resume all animations
      commit('SET_VISIBLE', true);
      console.log('focus')
    });

    ifvisible.setIdleDuration(20); //change if no activity for 120 segundos

    ifvisible.on("idle", function () {
      // Stop auto updating the live data
      commit('SET_IDLE', true);
      console.log('idle')
    });

    ifvisible.on("wakeup", function () {
      // go back updating data
      commit('SET_IDLE', false);
      console.log('wakeup')
    });

  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
