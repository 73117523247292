var client;

export default {
    install(Vue, options) {
        const config = {
            //clean: true, // Reserved session
            //connectTimeout: 4000, // Time out
            //reconnectPeriod: 4000, // Reconnection interval
            // Certification Information
            base: "siacap/c/" + window.base.channel + "/",
            channel: window.base.channel,
            clientId: "nfe_" + window.base.channel + "_" + new Date().getTime(),
            //   clientId: window.uniqid('nfe_'),
            // username: 'public',
            // password: 'public',
            //   username: 'printerclient',
            //   password: 'jn_printerclient_publico',

            username: "siacap",
            password: "siacap2023",

            //secureProtocol: 'TLS_method',
            host: "mqtt.jnissiac.com",
            port: 443,
            //    port: 443,
            // endpoint: '/ws',
            // endpoint: '/mqtt',
            //path: '/mqtt',
        };
        const subscribed = {};
        subscribed[config.base + "#"] = false;
        const toPublish = [];
        const callbacks = {};
        const events = {
            onSuccess(e) {
                if (e) {
                    console.log("1Connection succeeded!", e, this);
                    window.bus.emit("mqtt_status", "conectado");
                    console.log("trying to resubscribe", subscribed);
                    for (let i in subscribed) {
                        // if(subscribed[i]===false)
                        {
                            console.log("subscribing to ", i);
                            client.subscribe(i);
                            subscribed[i] = new Date().toISOString();
                        }
                    }
                    for (let i = toPublish.length; i > 0; i--) {
                        client.publish(toPublish.shift());
                    }
                } else {
                    console.log("2Disconnection succeeded!", e, this);
                    window.bus.emit("mqtt_status", "desconectado");
                }
            },
            onMessageArrived(message) {
                const topic = message.topic;
                message = message.payloadString;

                let lp = topic.split("/");
                lp = lp[lp.length - 1];

                // console.log('mqtt mqtt_' + topic)
                try {
                    let m = JSON.parse(message);
                    message = m;
                } catch (e) {}

                console.log(lp, message, topic);
                console.log("window.bus.emit", "mqtt_" + topic);
                window.bus.emit("mqtt_" + topic, {
                    topic: topic,
                    message: message,
                });

                const t = topic.split("/");
                t.pop();
                console.log("window.bus.emit", "mqtt2_" + t.join("/"));
                window.bus.emit("mqtt2_" + t.join("/"), {
                    topic: topic,
                    message: message,
                });

                let t1 = "";
                let t2 = topic.split("/");
                let cb;
                if (t2.length > 1) {
                    for (let i = 0; i < t2.length - 1; i++) {
                        t1 = t1 + t2[i] + "/";
                        cb = callbacks[t1 + "#"];
                        if (cb) {
                            for (let j = 0; j < cb.length; j++) {
                                if (cb[j] && cb[j].callback) {
                                    cb[j].callback(message);
                                }
                            }
                        }
                    }
                }
                {
                    cb = callbacks["#"];
                    if (cb) {
                        for (let j = 0; j < cb.length; j++) {
                            if (cb[j] && cb[j].callback) {
                                cb[j].callback(message);
                            }
                        }
                    }
                    cb = callbacks[topic];
                    if (cb) {
                        for (let j = 0; j < cb.length; j++) {
                            if (cb[j] && cb[j].callback) {
                                cb[j].callback(message);
                            }
                        }
                    }
                }

                // window.bus.emit('mqtt2_' + lp, {
                //   topic: topic,
                //   message: message,
                // })
            },
            onConnectionLost(responseObject) {
                if (responseObject.errorCode !== 0) {
                    console.log(
                        "onConnectionLost:" + responseObject.errorMessage
                    );
                    window.bus.emit("mqtt_status", "desconectado");
                }
                setTimeout(() => {
                    events.connect();
                }, 250);
            },
            onFailure(error) {
                console.log("mqtt Connection failed", error);
                window.bus.emit("mqtt_status", "desconectado");
            },
            onMessageDelivered(message) {
                console.log("onMessageDelivered", message);
            },
            init() {
                // window.base='nfe/c/'+window.base.channel+'/'

                client = new window.Paho.Client(
                    config.host,
                    Number(config.port),
                    "nfe" + new Date().getTime()
                );
                // window.mqtt_client_1=client

                //
                client.onConnectionLost = events.onConnectionLost;
                //
                client.onMessageArrived = events.onMessageArrived;
                //
                client.onMessageDelivered = events.onMessageDelivered;
                //         client.onFailure = events.onFailure

                this.connect();

                window.bus.emit("status_client");
            },
            disconnect() {
                return client.disconnect();
            },
            connect() {
                // if(!client.isConnected())
                return client.connect({
                    userName: config.username,
                    password: config.password,
                    onSuccess: events.onSuccess,
                    onFailure: events.onFailure,
                    //   onConnectionLost: events.onConnectionLost,
                    //   onMessageDelivered: events.onMessageDelivered,
                    //   onMessageArrived: events.onMessageArrived,
                    useSSL: true,
                    mqttVersion: 3,
                    //   onSuccess: () => {
                    //     console.log('Connected! 1')
                    //     // client.subscribe(`device/default`)
                    //     client.onConnectionLost = this.onConnectionLost
                    //     client.onMessageArrived = this.onMessage
                    // },
                    // onFailure: () => {
                    //     console.log('Failed to connect!')
                    // },
                });
            },
            publish(topic, message, options) {
                const stringMessage =
                    typeof message == "object"
                        ? JSON.stringify(message)
                        : message;
                const m = new window.Paho.Message(stringMessage);
                m.topic = topic;
                m.qos = 1;
                if (options) {
                    if (options.qos) m.qos = options.qos;
                    if (options.retain) m.retained = true;
                    if (options.retained) m.retained = true;
                }
                if (client.isConnected()) {
                    return client.publish(m);
                } else {
                    toPublish.push(m);
                    events.connect();
                }
            },
            subscribe(topic, callback) {
                if (topic[0] == "/") topic = config.base + topic;
                // else topic=topic.substring(1)
                console.log("topic", topic);

                const cb = {
                    id: new Date().getTime(),
                    callback: callback,
                };
                if (subscribed[topic] !== undefined) {
                    if (!callbacks[topic]) callbacks[topic] = [];
                    callbacks[topic].push(cb);
                    return cb.id;
                } else {
                    if (!callbacks[topic]) callbacks[topic] = [];
                    callbacks[topic].push(cb);
                    if (!client.isConnected()) {
                        subscribed[topic] = false;
                        console.log("to be subscribed to ", topic);
                        events.connect();
                    } else {
                        console.log("subscribing to ", topic);
                        subscribed[topic] = new Date().toISOString();
                        client.subscribe(topic);
                    }
                }
                return cb.id;
            },
            unsubscribe(topic, callbackid) {
                if ( !callbackid) {
                  if(subscribed[topic]){

                    if (callbacks[topic].length == 0) {
                      delete subscribed[topic];
                      delete callbacks[topic];
                      return client.unsubscribe(topic);
                    } else {
                      callbacks[topic].pop();
                      if (callbacks[topic].length == 0) {
                        delete subscribed[topic];
                        delete callbacks[topic];
                        return client.unsubscribe(topic);
                      }
                    }
                    }else{
                      for(let i in callbacks){
                        for(let j in callbacks[i]){
                          if(callbacks[i][j] && callbacks[i][j].id == topic){
                            delete callbacks[i][j]
                            let n=0;
                            for(let k in callbacks[i]){
                              if(callbacks[i][k] && callbacks[i][k].callback) n++;
                            }
                            if(n==0){
                              delete subscribed[i];
                              delete callbacks[i];
                              return client.unsubscribe(i);
                            }
                          }
                        }
                      }
                    }
                } else if (subscribed[topic]) {
                    let n = 0;
                    let found = false;
                    if (callbacks[topic].length == 0) {
                        delete subscribed[topic];
                        delete callbacks[topic];
                        return client.unsubscribe(topic);
                    } else {
                        for (let i = 0; i < callbacks[topic].length; i++) {
                            if (
                                callbacks[topic][i] &&
                                callbacks[topic][i].id == callbackid
                            ) {
                                found = true;
                                delete callbacks[topic][i];
                            } else {
                                if (callbacks[topic][i]) n++;
                            }
                        }
                        if (!found) {
                            callbacks[topic].pop();
                            n--;
                        }
                        if (n <= 0) {
                            delete subscribed[topic];
                            delete callbacks[topic];
                            return client.unsubscribe(topic);
                        }
                    }
                }
            },
        };
        console.log("mqtt init 3");
        events.init();

        Vue.prototype.$mqtt = {
            publish(topic, message, options) {
                return events.publish(topic, message, options);
            },
            subscribe(topic, callback) {
                return events.subscribe(topic, callback);
            },
            unsubscribe(topic, callbackid) {
                return events.unsubscribe(topic, callbackid);
            },
            connect() {
                return events.connect();
            },
            disconnect() {
                return events.disconnect();
            },
            config: config,
            events: events,
            client: client,
            subscribed: subscribed,
        };
    },
};

//  client=mqtt.connect("wss://mqtt.jnissiac.com:443",{

//   //clean: true, // Reserved session
//   connectTimeout: 4000, // Time out
//   reconnectPeriod: 4000, // Reconnection interval
//   // Certification Information
//   clientId: 'mqttjs_311be2c321',
//   // username: 'public',
//   // password: 'public',
//   username: 'admin',
//    password: 'adminadmin',
//    //secureProtocol: 'TLS_method',
//   host: 'mqtt.jnissiac.com',
//         port: 443,
//        // endpoint: '/ws',
//         // endpoint: '/mqtt',
//    //path: '/mqtt',
// })
// client.on('connect', () => {
//   console.log('Connection succeeded!')

// })
// client.on('error', error => {
//   console.log('Connection failed', error)
// })
// client.on('message', (topic, message) => {
//   //this.receiveNews = this.receiveNews.concat(message)
//   console.log(`Received message ${message} from topic ${topic}`)
// //  client.end()
// })
// client.subscribe('test/#',{} )
